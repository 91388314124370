import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Form,
    Container,
    Button,
    FormGroup,
    Breadcrumb,
    Navbar,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import { GetReceivableReport } from "./Report.action";
import { Link } from "react-router-dom";
import IconMenu from "../../../drawer/IconMenu";
import { getUserRes } from "../../AddUser/user.actions";


const SalesMenReceivableReport = (props) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [bookerId, setBookerId] = useState("");
    const [bookerName, setBookerName] = useState("");
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    const handleVisible = () => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: "Report Generated Successfully",
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = () => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went Wrong!",
            icon: "error",
            button: "Ok",
        });
    };

    const exportPDF = () => {
        props.GetReceivableReport({
            startDate: isUndefined(startDate) ? "" : startDate,
            endDate: isUndefined(endDate) ? "" : endDate,
            salesmenId: bookerId,
            bookerName: bookerName,
        },
            startDate,
            endDate,
            handleDangerVisible
        );
    };

    const filterdUserList = !isEmpty(props.userList) && props.userList.filter((e) => e.role == "salesmen");
    const BookerList = !isEmpty(filterdUserList) && filterdUserList.map((x) => {
        let data = { value: x._id, label: x.userName };
        return data;
    });
    const handleChangeBooker = (selectedOption) => {
        setBookerId(selectedOption.value);
        setBookerName(selectedOption.label);
    };

    useEffect(() => {
        props.getUser();
    }, []);

    return (
        <>
            <Navbar style={{ backgroundColor: "#81abd3" }}>
                <Container>
                    <Navbar.Brand style={{ color: "white" }}>
                        <b> SalesMen Receivable Report</b>
                    </Navbar.Brand>
                    <IconMenu />
                </Container>
            </Navbar>
            {props.isFetchingUser ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/home"> Home</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to="/report">Report</Link>{" "}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>SalesMen Receivable Report</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Form.Label>
                                        <b>Start Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    ></Form.Control>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Form.Label>
                                        <b>End Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    ></Form.Control>
                                </FormGroup>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label>
                                        <b>Select Salesmen *</b>
                                    </Form.Label>
                                    <Select
                                        isDisabled={isEmpty(BookerList)}
                                        // value={{ label: bookerName }}
                                        placeholder="Select Salesmen..."
                                        onChange={handleChangeBooker}
                                        options={BookerList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv" style={{ marginTop: "2%" }}>
                            {bookerId == "" ? (
                                <Button
                                    disabled
                                    style={{
                                        marginLeft: "2%",
                                        color: "white",
                                        width: "20%",
                                        border: "1px solid",
                                    }}
                                >
                                    Generate Report
                                </Button>) :
                                // ) : props.isFetchingVendorWiseSaleReport ? (
                                //   <Button>Generating PDF</Button>
                                // ) : 
                                (
                                    <Button onClick={() => exportPDF()}>
                                        Generate Report
                                    </Button>
                                )}
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUser: () => dispatch(getUserRes()),
        GetReceivableReport: (data, startDate, endDate, handleDangerVisible) => dispatch(GetReceivableReport(data, startDate, endDate, handleDangerVisible)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    vendorDataList: state.VendorReducer.vendorDataList,
    isFetchingVendor: state.VendorReducer.isFetchingVendor,
    productDataList: state.ProductReducer.productDataList,
    isFetchingProduct: state.ProductReducer.isFetchingProduct,
    vendorWiseSaleReportData: state.ReportReducer.vendorWiseSaleReportData,
    isFetchingVendorWiseSaleReport:
        state.ReportReducer.isFetchingVendorWiseSaleReport,
    isFetchingUser: state.UserReducer.isFetchingUser,
    userList: state.UserReducer.userList,
    areaDataList: state.AreaReducer.areaDataList,
    isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesMenReceivableReport);
