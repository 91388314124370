import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import "./style.css"
import { get } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { isEmpty, isUndefined, hasIn, isNull } from "lodash";

const LedgerTable = (props) => {

    useEffect(() => {

    }, []);

    return (
        <>
            {props.isFetchingSearchAccountLedgerTable ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingSearchAccountLedgerTable ? 'Loading...' : 'Loading...'}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">

                        <div className="sendDiv">
                            <Link to="/accounts/ledger">
                                <Button> Back to Search</Button>
                            </Link>
                        </div>

                        <MaterialTable
                            title={<div style={{ marginTop: '5px' }}><h5>{props.searchAccountLedgerTableList.levelFourName}</h5><h5>Date Range: {props.searchAccountLedgerTableList.startDate} - {props.searchAccountLedgerTableList.endDate}</h5>
                                <h5>Closing Balance :{props.searchAccountLedgerTableList.closingBalance}</h5>
                                <h5>Total Credit:{props.searchAccountLedgerTableList.creditSum}</h5>
                                <h5>Total Debit:{props.searchAccountLedgerTableList.debitSum}</h5>
                            </div>}
                            columns={[
                                { title: 'Date', field: 'voucherDate' },
                                { title: "Voucher Id", field: "vocManualNumber" },
                                //{ title: "Cross Account", field: "cross_acc", filtering: false },
                                { title: "Description", field: "voucherDescription" },
                                { title: "Bill No", field: "billNumber", },
                                //{ title: "Opening", field: "openingBalance",filtering: false,  },
                                { title: "Debit", field: "debit", render: rowData => !hasIn(rowData, 'debit') || isNull(rowData.debit) ? "" : rowData.debit.toLocaleString() },
                                { title: "Credit", field: "credit", render: rowData => !hasIn(rowData, 'credit') || isNull(rowData.credit) ? "" : rowData.credit.toLocaleString() },
                                { title: "Balance", field: "closingBalance" },
                                { title: 'DR/CR', field: 'closingBalanceSign' },
                                // {title: 'DR/CR', field: "accType"}
                            ]}
                            data={props.searchAccountLedgerTableList.data}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: false,
                                exportButton: false,
                                paging: true,
                                pageSize: 200,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};
const mapStateToProps = (state, ownProps) => ({
    searchAccountLedgerTableList: state.AccountLegderReducer.searchAccountLedgerTableList,
    isFetchingSearchAccountLedgerTable: state.AccountLegderReducer.isFetchingSearchAccountLedgerTable,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LedgerTable);